import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import './App.css';
import MainpageLayout from "./layout/mainpageLayout";

const Home = lazy(() => import('./pages/home'));
const CarRental = lazy(() => import('./pages/carrental'));
const RentalTypes = lazy(() => import('./pages/rental-types'));
const ContactUs = lazy(() => import('./pages/contactus'));
const JobOpening = lazy(() => import('./pages/jobopening'));
const Services = lazy(() => import('./pages/services'));
const CarDetails = lazy(() => import('./pages/car-details'));
const CarList = lazy(() => import('./pages/carlist'));
const JobDetails = lazy(() => import('./pages/job-details'));

const ServiceDetails = lazy(() => import('./pages/services/service-details'));
const Terms = lazy(() => import('./pages/terms'));
const RecruitmentServices = lazy(() => import('./pages/services/recruitment'))
const ITAndMarketingServices = lazy(() => import('./pages/services/it'))
const EngineeringServices = lazy(() => import('./pages/services/engineering'))
const SettlementServices = lazy(() => import('./pages/services/settlement'))
const CarRentalServices = lazy(() => import('./pages/services/car_rental'))

function App() {
  return (
    <Router>
      <Suspense fallback={<div id="preloader" />}>
        <Routes>
          <Route exact path="*" element={<p>404 page Not Found</p>} />
          <Route element={<MainpageLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/types" element={<RentalTypes />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/job-openings" element={<JobOpening />} />
            {/* <Route exact path="/car-rental" element={<CarRental />} /> */}
            <Route exact path="/car-list" element={<CarList />} />
            <Route exact path="/car-list/car-details" element={<CarDetails />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/job-openings/:JobId" element={<JobDetails />} />
            <Route exact path="/services/:ServiceId" element={<ServiceDetails />} />
            {/* <Route exact path="/services/recruitment" element={<RecruitmentServices />} />
            <Route exact path="/services/IT" element={<ITAndMarketingServices />} />
            <Route exact path="/services/EP" element={<EngineeringServices />} />
            <Route exact path="/services/settlement" element={<SettlementServices />} />
            <Route exact path="/services/car-rental" element={<CarRentalServices />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;

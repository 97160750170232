import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const notifyError = (message) => { toast.error(message); }

const HandleResponse = (values) => {
    if (values.response.status == 422) {
        notifyError(values.response.data.message)
    }
    if (values.response.status == 404) {
        notifyError(values.response.statusText)
    }
    if (values.response.status == 401) {
        window.location.href = "/"
    }
}

// var apiUrl = "http://13.54.175.112/:3000/";
// var apiUrl = "http://localhost:8000/";
var apiUrl = "https://live.futureme.group/";
// var apiUrl = "https://consultant-backend.onrender.com/";
// var apiUrl = "https://futureme-backend.onrender.com/"
export default class ApiCallService {

    constructor() {

    }

    postApi(url, data) {
        const tokenData = window.sessionStorage.getItem("token") ? window.sessionStorage.getItem("token")?.toString() : '';

        var resData = axios.post(apiUrl + url, data).then(function (response) { //, { headers: { "Authorization": `Bearer ${tokenData}` } }
            return response;
        })
            .catch(function (error) {
                HandleResponse(error)
            });

        return resData;
    }

    getApi(url) {
        const tokenData = window.sessionStorage.getItem("token") ? window.sessionStorage.getItem("token")?.toString() : '';
        var resData = axios.get(apiUrl + url) //, { headers: { "Authorization": `Bearer ${tokenData}` } }
            .then(function (response) {

                return response;
            })
            .catch(function (error) {
                HandleResponse(error)
            });

        return resData;
    }

    getByIdApi(url, id) {

        const tokenData = window.sessionStorage.getItem("token") ? window.sessionStorage.getItem("token")?.toString() : '';
        // const config = { headers: { "Authorization": `Bearer ${tokenData}` } }
        var resData = axios.get(`${apiUrl}${url}/${id}`) //config
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                HandleResponse(error)
            });

        return resData;
    }

    
}
import React from 'react'

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer id="footer-section" className="footer-section bg-black clearfix">
            {/* <div className="footer-top sec-ptb-100 clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="ftr-brand-area">
                                <a href="index-1.html" className="brand-logo">
                                    <img
                                        src="assets/images/footer-logo.png"
                                        alt="brand logo not found"
                                    />
                                </a>
                                <p className="text-justify clr-white">
                                    Lorem Ipsum is simply dummy text .Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="useful-links">
                                <h2 className="title-small clr-white mb-50">useful link</h2>
                                <ul>
                                    <li>
                                        <a href="#!">
                                            <i className="ion-ios-arrow-thin-right" /> About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="ion-ios-arrow-thin-right" /> Our Team
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="ion-ios-arrow-thin-right" /> Our Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="ion-ios-arrow-thin-right" /> Terms &amp;
                                            Condition
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="ion-ios-arrow-thin-right" /> Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="ion-ios-arrow-thin-right" /> Help &amp;
                                            contact
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <i className="ion-ios-arrow-thin-right" /> FAQ
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="recent-post">
                                <h2 className="title-small clr-white mb-50">recent post</h2>
                                <ul>
                                    <li>
                                        <span className="img">
                                            <img
                                                src="assets/images/recent-post/post1.jpg"
                                                alt="image_not_found"
                                            />
                                        </span>
                                        <div className="contant">
                                            <a href="#!">Bitcoin Price Primed To Test</a>
                                            <small>1 jan 2018 - 11:28 am</small>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="img">
                                            <img
                                                src="assets/images/recent-post/post2.jpg"
                                                alt="image_not_found"
                                            />
                                        </span>
                                        <div className="contant">
                                            <a href="#!">Launch Coin Ultimate Card</a>
                                            <small>1 jan 2018 - 11:28 am</small>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="img">
                                            <img
                                                src="assets/images/recent-post/post3.jpg"
                                                alt="image_not_found"
                                            />
                                        </span>
                                        <div className="contant">
                                            <a href="#!">Payouts To Visa/MasterCard</a>
                                            <small>1 jan 2018 - 11:28 am</small>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="contact-info mb-30">
                                <h2 className="title-small clr-white mb-50">contact us</h2>
                                <ul>
                                    <li>
                                        <i className="ion-ios-location" />
                                        3256 Mouse Roda, New York, USA
                                    </li>
                                    <li>
                                        <i className="ion-android-call" />
                                        +880 123 456 789
                                    </li>
                                    <li>
                                        <i className="ion-email" />
                                        <a
                                            href="https://demo.xstheme.com/cdn-cgi/l/email-protection"
                                            className="__cf_email__"
                                            data-cfemail="4f262129200f3c263b2a212e222a612c2022"
                                        >
                                            [email&nbsp;protected]
                                        </a>
                                    </li>
                                    <li>
                                        <i className="ion-clock" />
                                        Mon - Fri :- 9:00 am - 19:00 pm
                                    </li>
                                </ul>
                            </div>
                            <div className="social-list">
                                <ul>
                                    <li className="social-link">
                                        <a href="#!">
                                            <i className="ion-social-facebook" />
                                        </a>
                                    </li>
                                    <li className="social-link">
                                        <a href="#!">
                                            <i className="ion-social-twitter" />
                                        </a>
                                    </li>
                                    <li className="social-link">
                                        <a href="#!">
                                            <i className="ion-social-google" />
                                        </a>
                                    </li>
                                    <li className="social-link">
                                        <a href="#!">
                                            <i className="ion-social-linkedin" />
                                        </a>
                                    </li>
                                    <li className="social-link">
                                        <a href="#!">
                                            <i className="ion-social-instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="footer-bottom clearfix">
                <div className="container">
                    <p className="copyright text-center">
                        © Powered by &nbsp;  
                        <a
                            href="https://www.tripediasolutions.com.au"
                            className="clr-yellow"
                        >
                            <u>Tripedia Solutions</u>
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

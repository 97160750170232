import React, { Suspense, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../common/Apiservice';

export default function Header() {
    const Api = new ApiService();

    const location = useLocation();

    const [random, setRandom] = useState();
    
    const [servicesMenu, setServicesMenu] = useState([]);
    const [loading, setLoading] = useState(true);

    const slideMenu = () => {
        const menuList = document.querySelector('#menu-container .menu-list');
        const activeState = menuList.classList.contains('active');
        menuList.style.left = activeState ? '0%' : '-100%';
    };

    const handleMenuClick = (event) => {
        const hamburgerMenu = document.querySelector('.hamburger-menu');
        hamburgerMenu.classList.toggle('open');

        const menuList = document.querySelector('.menu-list');
        menuList.classList.toggle('active');
        slideMenu();

        document.body.classList.toggle('overflow-hidden');
    };

    const fetchsubMenuData = async () => {
        try {
            setLoading(true);
            const response = await Api.postApi('api/service/getall', { page: 0, limit: 100 });
            setServicesMenu(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching jobs:', error);
        }
    };

    useEffect(() => {
        setRandom(Math.floor(Math.random() * (100 - 1 + 1)) + 1);
        fetchsubMenuData();
    }, [location.pathname])

    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const menuItems = [
        {
            name: 'Car Rental',
            path: '#',
            children: [
                {
                    name: 'Brand', path: '#',
                    nested_children: [
                        {
                            name: "Chevrolet",
                            path: "/car-list?brand=chevrolet"
                        },
                        {
                            name: "Hyundai",
                            path: "/car-list?brand=hyundai"
                        },
                        {
                            name: "Kia",
                            path: "/car-list?brand=kia"
                        },
                        {
                            name: "MG",
                            path: "/car-list?brand=mg"
                        },
                        {
                            name: "Mazda",
                            path: "/car-list?brand=mazda"
                        },
                    ]

                },
                {
                    name: 'Car Types',
                    path: '#',
                    nested_children: [
                        {
                            name: "Luxury Cars",
                            path: "/car-list?type=luxury"
                        },
                        {
                            name: "Sports Cars",
                            path: "/car-list?type=sports"
                        },
                        {
                            name: "Suv Cars",
                            path: "/car-list?type=suv"
                        },
                        {
                            name: "Economic Cars",
                            path: "/car-list?type=economic"
                        },
                        {
                            name: "Convertible Cars",
                            path: "/car-list?type=convertible"
                        },
                        {
                            name: "Electrical Cars",
                            path: "/car-list?type=electrical"
                        }
                    ]
                },

                {
                    name: 'Rental Types',
                    path: '/types',
                    // nested_children: [
                    //     {
                    //         name: "Annual Rental",
                    //         path: "/car-list?type=luxury"
                    //     },
                    //     {
                    //         name: "Long Term Rental",
                    //         path: "/car-list?type=sports"
                    //     },
                    //     {
                    //         name: "Short Term Rental",
                    //         path: "/car-list?type=suv"
                    //     },
                    // ]
                }
            ]
        },

    ];

    return (
        <Suspense fallback={<div>Loading header...</div>}>
            <header
                id="header-section"
                className="header-section cd-auto-hide-header clearfix"
            >
                <div className="header-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="brand-logo-area">
                                    <a href="/" className="brand-logo">
                                        <img style={{ height: "80px" }} src="assets/images/logo.jpg" alt="brand logo not fond" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="contact-info">
                                    <ul>
                                        <li>
                                            <span className="icon">
                                                <i className="ion-ios-location" />
                                            </span>
                                            <div className="content">
                                                <h4 className="title-xsmall">AL BARSHA</h4>
                                                <h4 className="title-xsmall">DUBAI,</h4>
                                                <h4 className="title-xsmall">UNITED ARAB EMIRATES</h4>
                                            </div>
                                        </li>
                                        <li>
                                            <span className="icon">
                                                <i className="ion-android-call" />
                                            </span>
                                            <div className="content">
                                                <h3 className="title-xsmall">Call us</h3>
                                                <h4>+971 56 131 1200</h4>
                                            </div>
                                        </li>
                                        <li >
                                            <span className="icon">
                                                <i className="ion-clock" />
                                            </span>
                                            <div className="content">
                                                <h3 className="title-xsmall">Office Time</h3>
                                                <h4>Mon - Sat :- 8:00 AM - 06:00 PM</h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="mainmenu clearfix">
                            <ul>
                                <li className={window.location.pathname === "/" ? "active" : ""}>
                                    <Link to="/">home</Link>
                                </li>
                                {/* <li className={window.location.pathname === "/services" ? "active" : "" } onMouseEnter={() => setIsServicesOpen(true)} onMouseLeave={() => setIsServicesOpen(false)}>
                                    <Link to="/services">Services</Link>
                                    <ul className={`dropdown-content ${isServicesOpen ? 'show' : ''}`}>
                                        <li><Link to="/services/recruitment">Recruitment Services</Link></li>
                                        <li><Link to="/services/IT">IT And Marketing Services</Link></li>
                                        <li><Link to="/services/EP">Engineering, Procurement And Services</Link></li>
                                        <li><Link to="/services/settlement">Initial Settlement Services</Link></li>
                                        <li><Link to="/services/car-rental">Car Rental</Link></li>
                                    </ul>
                                </li> */}
                                <li className={window.location.pathname === "/services" ? "active" : "" } onMouseEnter={() => setIsServicesOpen(true)} onMouseLeave={() => setIsServicesOpen(false)}>
                                    <Link to="/services">Services</Link>
                                    {
                                        servicesMenu?.responseData?.data?.length > 0 ?  
                                                
                                                <ul className={`dropdown-content ${isServicesOpen ? 'show' : ''}`}>
                                                {
                                                    servicesMenu?.responseData?.data?.map((obj, idx) => {
                                                        return <li key={idx}><Link to={{ pathname:"/services/"+obj._id }}>{obj.title}</Link></li>
                                                    })
                                                }
                                                </ul>
                                            
                                        :<ul className={`dropdown-content`}></ul>
                                    }
                                </li>
                                
                                <li className={window.location.pathname === "/job-openings" ? "active" : ""}>
                                    <Link to="/job-openings">Job Openings</Link>
                                </li>

                                {/* {menuItems.map((item, index) => (
                                    <MenuItem key={index} item={item} />
                                ))} */}

                                {/* <li className={window.location.pathname === "/terms" ? "active" : ""}>
                                    <Link to="/terms">TERMS</Link>
                                </li> */}

                                <li className={window.location.pathname === "/contact-us" ? "active" : ""}>
                                    <Link to="/contact-us">Contact us</Link>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <div className="cart-area">
                <div id="cart-wrapper" className="cart-wrapper">
                    <div id="cart-dismiss" className="dismiss">
                        <i className="ion-android-close" />
                    </div>
                    <div id="cart-sidebar" className="cart-sidebar">
                        <div className="cart-item mb-30 clearfix">
                            <div className="cart-img float-left">
                                <img
                                    className="block"
                                    src="assets/images/shop/img1.png"
                                    alt="Image"
                                />
                            </div>
                            <div className="cart-content">
                                <h2 className="title-xsmall">Lorem ipsum dolor.</h2>
                                <p className="mb-15">Lorem Ipsum is simply dummy text...</p>
                                <div>
                                    <a href="#!" className="remove-btn float-right">
                                        Remove
                                    </a>
                                    <span className="amount float-left">$69.00</span>
                                </div>
                            </div>
                        </div>
                        <div className="cart-item mb-30 clearfix">
                            <div className="cart-img float-left">
                                <img
                                    className="block"
                                    src="assets/images/shop/img1.png"
                                    alt="Image"
                                />
                            </div>
                            <div className="cart-content">
                                <h2 className="title-xsmall">Lorem ipsum dolor.</h2>
                                <p className="mb-15">Lorem Ipsum is simply dummy text...</p>
                                <div>
                                    <a href="#!" className="remove-btn float-right">
                                        Remove
                                    </a>
                                    <span className="amount float-left">$39.00</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="total-amount mb-30 clearfix">
                            <h6 className="float-left">total:-</h6>
                            <h6 className="float-right">$108.00</h6>
                        </div>
                        <div className="text-center">
                            <a href="checkout.html" className="custom-btn waves-effect">
                                Check Out Now
                                <span>
                                    <i className="ion-arrow-right-c" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="cart-overlay" />
            </div>

            {/* Mobile sidebar */}

            <div className="altranative-header">
                <div id="menu-container">
                    <div className="menu-wrapper">
                        <div className="row">
                            <button type="button" className="alt-menu-btn float-left"
                                onClick={handleMenuClick}>
                                <span className="hamburger-menu" />
                            </button>
                            <div className="logo-area">
                                <a href="/">
                                    <img src="assets/images/logo.jpg" alt="Logo_not_found" />
                                </a>
                            </div>
                            {/* <div className="cart-btn pulse float-right">
                                <button type="button" className="cart-Collapse">
                                    <i className="ion-android-cart" />
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <ul className="menu-list accordion">
                        <li className={window.location.pathname === "/" ? "card active" : "card"}>
                            <Link className="menu-link" to="/" onClick={() => handleMenuClick()}>Home</Link>
                        </li>
                        <li className={window.location.pathname === "/services" ? "card active" : "card"}>
                            <Link className="menu-link" to="/services" onClick={() => handleMenuClick()}>Services</Link>
                        </li>

                        <li className={window.location.pathname === "/job-openings" ? "card active" : "card"}>
                            <Link className="menu-link" to="/job-openings" onClick={() => handleMenuClick()}>Job Openings</Link>
                        </li>

                        {/* {
                            menuItems.map((obj, idx) => (
                                <li key={idx} className={window.location.pathname.includes("car-list") || window.location.pathname.includes("types") ? "card active" : "card"}>
                                    <div className="card-header" id={`heading${idx}`}>
                                        <button
                                            className="menu-link"
                                            data-toggle="collapse"
                                            data-target={`#collapsesix${idx}`}
                                            aria-expanded="true"
                                            aria-controls={`collapsesix${idx}`}
                                        >
                                            {obj.name}
                                        </button>
                                    </div>
                                    <ul
                                        id={`collapsesix${idx}`}
                                        className="submenu collapse"
                                        aria-labelledby={`heading${idx}`}
                                        data-parent="#accordion"
                                    >
                                        {obj.children && obj.children.map((child, childIdx) => (
                                            <li key={childIdx} className="card">
                                                <div className="card-header" id={`heading${idx}${childIdx}`}>
                                                    <Link to={child.path}
                                                        onClick={() => !child.nested_children && handleMenuClick()}
                                                        className="menu-link"
                                                        data-toggle="collapse"
                                                        data-target={`#collapsesix${idx}${childIdx}`}
                                                        aria-expanded="true"
                                                        aria-controls={`collapsesix${idx}${childIdx}`}
                                                    >
                                                        {child.name}
                                                    </Link>
                                                </div>
                                                <ul
                                                    id={`collapsesix${idx}${childIdx}`}
                                                    className="submenu collapse"
                                                    aria-labelledby={`heading${idx}${childIdx}`}
                                                    data-parent={`#collapsesix${idx}`}
                                                >
                                                    {

                                                        child.nested_children && child.nested_children.map((nestedChild, nestedIdx) => (
                                                            <li key={nestedIdx}>
                                                                <Link onClick={() => handleMenuClick()} to={nestedChild.path}>{nestedChild.name}</Link>
                                                            </li>
                                                        ))

                                                    }
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))
                        } */}

                        {/* <li className={window.location.pathname === "/terms" ? "card active" : "card"}>
                            <Link className="menu-link" to="/terms" onClick={() => handleMenuClick()}>Terms</Link>
                        </li> */}

                        <li className={window.location.pathname === "/contact-us" ? "card active" : "card"}>
                            <Link className="menu-link" to="/contact-us" onClick={() => handleMenuClick()}>Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </Suspense>


    )
}



function MenuItem({ item }) {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <li className={isOpen || (window.location.pathname.includes('types') || window.location.pathname.includes('car-list')) ? 'dropdown-btn active' : 'dropdown-btn'}>

            <Link to={item.path || '#'} onClick={handleToggle}>{item.name}</Link>

            {item.children && (
                <div className="dropdown-menu-list">
                    <ul>
                        {item.children.map((subItem, index) => (
                            <li key={index} className={subItem.nested_children ? 'nested-dropdown-btn' : ''}>
                                <Link to={subItem.path || '#'} onClick={(e) => {
                                    e.preventDefault();
                                    handleToggle();
                                    navigate(subItem.path);
                                }}>
                                    <i className="ion-android-arrow-dropright"></i>
                                    {subItem.name}
                                </Link>
                                {subItem.nested_children && (
                                    <ul>
                                        {subItem.nested_children.map((nestedItem, nestedIndex) => (
                                            <li key={nestedIndex}>
                                                <Link to={nestedItem.path || '#'} onClick={handleToggle}>
                                                    <i className="ion-android-arrow-dropright"></i>
                                                    {nestedItem.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}



